.masterHead-heading {
  font-size: 2.5rem;
  font-weight: 700;
  line-height: 3.25rem;
  /*  padding-top: 10.5rem; */
  /* margin-bottom: 2rem; */
  font-family: "Circular Std";
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
  .tab-button {
    height: 55px;
    width: 100%;
    display: block;
    width: 100%;
    border: none;
    color: #8992a4;
    background-color: #132649;
    font-family: "Circular Std Medium";
    font-size: 12px;
    cursor: pointer;
    text-align: center;
    position: relative;
  }

  .bottom-line {
    background: #ffbb51;
    width: 40px;
    height: 70%;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
    margin-left: -1rem;
  }

  .HomeBg {
    display: none;
    width: 100%;
  }

  .otpInput {
    height: 80px !important;
    background: "#f6f6f8";
    border: 0 !important;
    outline: none !important;
    border-radius: 15;
    font-size: 25px;
    padding-left: 2.5rem !important;
  }

  .masterHead-heading {
    font-size: 2.5rem;
    font-weight: 700;
    line-height: 3.25rem;
    /*  padding-top: 10.5rem; */
    /* margin-bottom: 2rem; */
    font-family: "Circular Std";
  }

  .sectionHead {
    font-family: "Circular Std Medium";
    margin-top: 2rem;
    margin-bottom: 0.5rem;
    color: black !important;
  }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
  .tab-button {
    height: 55px;
    width: 100%;
    display: block;
    width: 100%;
    border: none;
    color: #8992a4;
    background-color: #132649;
    font-family: "Circular Std Medium";
    font-size: 13px;
    cursor: pointer;
    text-align: center;
    position: relative;
  }

  .bottom-line {
    background: #ffbb51;
    width: 40px;
    height: 70%;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
    margin-left: -1rem;
  }

  .HomeBg {
    height: 610px;
    background-image: url("../assets/img/image.png");
    background-repeat: no-repeat;
    background-size: contain;
    background-color: #00071a;
    box-shadow:
      inset 0 130px 1000px -80px #01081a,
      inset 0 -250px 1000px -40px #01081a;
  }

  .otpInput {
    height: 80px !important;
    background: "#f6f6f8";
    border: 0 !important;
    outline: none !important;
    border-radius: 15;
    font-size: 25px;
    padding-left: 2.5rem !important;
  }

  .masterHead-heading {
    font-size: 2.5rem;
    font-weight: 700;
    line-height: 3.25rem;
    /*padding-top: 10.5rem; */
    /* margin-bottom: 2rem; */
    font-family: "Circular Std";
  }

  .sectionHead {
    font-family: "Circular Std Medium";
    color: black !important;

    margin-top: 3rem;
    margin-bottom: 2rem;
  }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
  .tab-button {
    height: 55px;
    width: 100%;
    display: block;
    width: 100%;
    border: none;
    color: #8992a4;
    background-color: #132649;
    font-family: "Circular Std Medium";
    font-size: 13px;
    cursor: pointer;
    text-align: center;
    position: relative;
  }

  .bottom-line {
    background: #ffbb51;
    width: 40px;
    height: 70%;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
    margin-left: -1rem;
  }

  .HomeBg {
    height: 610px;
    background-image: url("../assets/img/image.png");
    background-repeat: no-repeat;
    background-size: contain;
    background-color: #00071a;
    box-shadow:
      inset 0 130px 1000px -80px #01081a,
      inset 0 -250px 1000px -40px #01081a;
  }

  .masterHead-heading {
    font-size: 2.5rem;
    font-weight: 700;
    /* line-height: 3.25rem;
    padding-top: 10.5rem; */
    font-family: "Circular Std";
  }

  .otpInput {
    height: 80px !important;
    background: "#f6f6f8";
    border: 0 !important;
    outline: none !important;
    border-radius: 15;
    font-size: 25px;
    padding-left: 2.5rem !important;
  }

  .sectionHead {
    margin-top: 3rem;
    margin-bottom: 2rem;
    font-family: "Circular Std Medium";
    color: black !important;
  }
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
  .tab-button {
    height: 60px;
    width: 100%;
    display: block;
    width: 100%;
    border: none;
    color: #8992a4;
    background-color: #132649;
    font-family: "Circular Std Medium";
    font-size: 14px;
    cursor: pointer;
    text-align: center;
    position: relative;
  }

  .bottom-line {
    background: #ffbb51;
    width: 55px;
    height: 90%;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
    margin-left: -1rem;
  }

  .HomeBg {
    height: 610px;
    background-image: url("../assets/img/image.png");
    background-repeat: no-repeat;
    background-size: contain;
    background-color: #00071a;
    box-shadow:
      inset 0 130px 1000px -80px #01081a,
      inset 0 -250px 1000px -40px #01081a;
  }

  .masterHead-heading {
    font-size: 2.5rem;
    font-weight: 700;
    /* line-height: 3.25rem;
    padding-top: 10.5rem; */
    font-family: "Circular Std";
  }

  .otpInput {
    height: 80px !important;
    background: "#f6f6f8";
    border: 0 !important;
    outline: none !important;
    border-radius: 15;
    font-size: 25px;
    padding-left: 3rem !important;
  }

  .sectionHead {
    margin-top: 5rem;
    margin-bottom: 3rem;
    font-family: "Circular Std Medium";
    color: black !important;
  }
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
  .tab-button {
    height: 60px;
    width: 100%;
    display: block;
    width: 100%;
    border: none;
    color: #8992a4;
    background-color: #132649;
    font-family: "Circular Std Medium";
    font-size: 18px;
    cursor: pointer;
    text-align: center;
    position: relative;
  }

  .bottom-line {
    background: #ffbb51;
    width: 60px;
    height: 100%;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
    margin-left: -1rem;
  }

  .HomeBg {
    height: 610px;
    background-image: url("../assets/img/image.png");
    background-repeat: no-repeat;
    background-size: contain;
    background-color: #00071a;
    box-shadow:
      inset 0 130px 1000px -80px #01081a,
      inset 0 -250px 1000px -40px #01081a;
  }

  .masterHead-heading {
    font-size: 4rem;
    font-weight: 700;
    line-height: 4.25rem;
    /* padding-top: 10.5rem;
    margin-left: 12rem; */
    font-family: "Circular Std";
  }

  .otpInput {
    height: 80px !important;
    background: "#f6f6f8";
    border: 0 !important;
    outline: none !important;
    border-radius: 15;
    font-size: 25px;
    padding-left: 3rem !important;
  }
}

.subHeading {
  font-family: "Circular Std Book";
  font-size: 1.3rem;
  color: #847e7ede;
  padding-top: 10px;
  padding-left: 10px;
}

.bg-image-container {
  position: relative;
  width: 100%;
  overflow: hidden;
  background-size: cover;
  background-position: center;
  box-shadow: 0 -100px 100px rgba(0, 0, 0, 0.9) inset;
}

.sectionHead {
  margin-top: 5rem;
  margin-bottom: 3rem;
  font-family: "Circular Std Medium";
  color: "black" !important;
}

@-webkit-keyframes shine {
  100% {
    left: 125%;
  }
}

@keyframes shine {
  100% {
    left: 125%;
  }
}

.bg-image-container:hover {
  filter: grayscale(100%);
}

.text-container {
  max-width: 75%;
  position: absolute;
  left: 20px;
  bottom: 20px;
  font-size: 20px;
  color: white;
}

.label {
  font-size: 10px;
  text-transform: uppercase;
  margin-bottom: 5px;
  color: #ccc;
}

.slick-slide {
  padding: 0 10px;
}

.destinationText {
  font-family: "Circular Std Book";
  font-size: 23px;
  color: #8993a4;
}

.destination-Box {
  position: relative;
  width: 100%;
  overflow: hidden;
  /* background-size: auto 100%; */
  background-position: center;
}

.destination {
  display: grid;
  justify-content: center;
}

.destination:hover .destinationText {
  /* this works */
  color: black;
  font-family: "Circular Std Medium";
}

.linearBg {
  background-image: url("../assets/img/linearBg.png");
  margin-top: 5rem;
  margin-bottom: 5rem;
}

.latesCars-Box {
  height: 289.51;
  width: 300.9;
  font-family:
    system-ui,
    -apple-system,
    BlinkMacSystemFont,
    "Segoe UI",
    Roboto,
    Oxygen,
    Ubuntu,
    Cantarell,
    "Open Sans",
    "Helvetica Neue",
    sans-serif;
}

.latestCars-imageBox {
  position: relative;
  width: 100%;
  overflow: hidden;
  background-size: cover;
  background-position: center;
}

.latestCars-title {
  font-size: 22px;
  color: #132649;
  font-family: "Circular Std Bold";
  text-overflow: ellipsis;
  white-space: nowrap;
  width: "98%";
  overflow: hidden;
}

.hostCard {
  box-shadow: 2px 2px 12px 3px #e0eaed8f;
}

.hostCard-subHead {
  font-weight: 500;
  font-family: "Circular Std Book";
  color: #8993a4;
  font-size: 17px;
}

.userNav {
  font-size: 17px !important;
  font-family: "Circular Std Medium";
}

.highlight {
  color: #132649;
  font-weight: 500;
  text-decoration: underline;
  -webkit-text-decoration-color: #f1ae01;
  /* Safari */
  text-decoration-color: #f1ae01;
  text-decoration-thickness: 3px;
  text-underline-position: under;
  cursor: pointer;
  font-family: "Circular Std Medium" !important;
}

.hint-text {
  color: #5a5a5a;
  text-underline-position: under;
  text-decoration-color: #b8adad;
  font-family: "Circular Std Medium";
  font-size: 18px;
  /* text-decoration-thickness: 2px; */
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.PhoneInputInput {
  border-radius: 3%;
  outline: none;
  width: 220% !important;
}

.PhoneInput {
  background: #f6f6f8;
  padding-left: 15px;
}

.flag-dropdown {
  border: none !important;
  padding-left: 1rem !important;
}

.telInput {
  padding-left: 4rem !important;
  height: 54px !important;
  font-family:
    system-ui,
    -apple-system,
    BlinkMacSystemFont,
    "Segoe UI",
    Roboto,
    Oxygen,
    Ubuntu,
    Cantarell,
    "Open Sans",
    "Helvetica Neue",
    sans-serif;
}

.smallBtn {
  font-weight: bold;
  /* background-image: linear-gradient(to right, #f1ae01, #ffbb51) !important; */
  background-color: #f1ae01;
  border-radius: 10px !important;
  border: none;
  color: white !important;
  transition: none !important;
  height: 38px !important;
  outline: none !important;
  border: none !important;
  font-size: 16px !important;
  font-family:
    system-ui,
    -apple-system,
    BlinkMacSystemFont,
    "Segoe UI",
    Roboto,
    Oxygen,
    Ubuntu,
    Cantarell,
    "Open Sans",
    "Helvetica Neue",
    sans-serif !important;
}

.modal-title {
  color: #132649 !important;
  font-size: 25px;
}

.modal-header {
  border-bottom: none !important;
}

.box-text {
  /* font-weight: 600; */
  font-family: "Circular Std Bold";
  font-size: 23px;
}

.host-title {
  font-family: "Circular Std Bold";
  font-size: 22px;
  margin-top: -0.5rem;
}

.blog-head {
  color: #f1ae01;
  font-family: "Circular Std Bold";
  font-size: 19px;
}

.card-title {
  font-family: "Circular Std Bold" !important;
  font-size: 22px;
}

.footer-head {
  font-family: "Circular Std Bold";
  font-size: 22px;
  color: black;
}

.footer-subHead {
  font-family: "Circular Std Book";
  font-size: 18px;
}

.menu-text {
  font-family: "Circular Std Book";
  font-size: 18px;
}

.login-footer-text {
  font-family: "Circular Std Book";
  font-size: 18px;
}

.register-title {
  color: #132649;
  font-family: "Circular Std Medium";
  font-size: 22px;
}

.home-search-text {
  font-family: "Circular Std Book";
  font-size: 18px;
}

.modal-title {
  /* color: red !important; */
  font-family: "Circular Std Black" !important;
}

.review-car-title {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  width: 95%;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  font-size: 22px;
  color: #132649;
  font-family: "Circular Std Bold";
}

.noData {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 25px;
  font-family: "Circular Std Bold";
  color: #132649;
}

::-webkit-scrollbar {
  width: 3px;
}

::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(112, 105, 105, 0.3);
}

::-webkit-scrollbar-thumb {
  background-color: darkgrey;
}

.dashBoard-total-title {
  font-family: "Circular Std Black";
  font-size: 1.563rem;
  color: #132649;
}

.dashBoard-total-subTitle {
  font-family: "Circular Std Book";
  font-size: 20px;
  color: #132649;
}

.dashBoard-col {
  margin-left: -0.7rem;
  margin-top: 1rem !important;
}

.carOwner-heading {
  font-family: "Circular Std Medium";
  color: #9aa2b1;
  font-size: 25px;
}

.myCar-deleteIcon {
  position: absolute;
  top: 10px !important;
  right: 10px !important;
  border-radius: 25px !important;
  border: 1px solid white;
  background: #00000059 !important;
  width: 50px;
  height: 50px;
  display: flex !important;
  justify-content: center;
  align-items: center;
  backdrop-filter: blur(3px);
}
.myCar-deleteIcon1 {
  position: absolute;
  top: 10px !important;
  right: 10px !important;
  border-radius: 25px !important;
  border: 1px solid white;
  background: white !important;
  width: 40px;
  height: 40px;
  display: flex !important;
  justify-content: center;
  align-items: center;
  backdrop-filter: blur(3px);
}

.editIcon {
  position: absolute;
  top: 10px !important;
  right: 20px !important;
  border-radius: 25px !important;
  border: 1px solid white;
  background: white !important;
  height: 40px;
  display: flex !important;
  backdrop-filter: blur(3px);
}

.addCar-deleteIcon {
  position: absolute;
  top: 8px !important;
  right: 10px !important;
  border-radius: 25px !important;
  border: 1px solid white;
  /* background: #ffffff63 !important; */
  background: #00000059 !important;
  width: 35px;
  height: 35px;
  display: flex !important;
  justify-content: center;
  align-items: center;
  backdrop-filter: blur(1px);
}

.tab-button.active {
  color: white;
  font-family: "Circular Std Bold";
  width: 100%;
}

.myCar-label {
  font-family: "Circular Std Black";
  color: #132649;
  font-size: 22px;
}

.circularMedium {
  font-family: "Circular Std Medium";
}

.circularBook {
  font-family: "Circular Std Book";
}

.circularBold {
  font-family: "Circular Std Bold";
}

.circularBlack {
  font-family: "Circular Std Black";
}

.myCar-addImageBox {
  border: 2px dashed #6cd2c2;
  height: 130px !important;
  width: 125px !important;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 15px;
}
.myCar-addImageBoxBlog {
  border: 2px dashed #6cd2c2;
  height: 21rem;
  width: 35rem;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 15px;
}

.circularMedium {
  font-size: 16px;
}

@media (max-width: 768px) {
  .myCar-addImageBoxBlog {
    height: 20rem;
    width: 20rem;
  }
}
/* @media (max-width: 768px) {
  .myCar-addImageBoxBlog {
    height: 25rem;
    width: 20rem;
  }
}

@media (max-width: 576px) {
  .myCar-addImageBoxBlog {
    height: 25rem;
    width: 20rem;
  }
}
@media (max-width: 1024px) {
  .myCar-addImageBoxBlog {
    height: 20rem;
    width: 20rem;
  }
} */
.carOwner-tab-title {
  font-family: "Circular Std Medium";
  color: #8f9ca7;
}

.sort-List {
  background: white;
  /* background-image: linear-gradient(to right, #f1ae01, #ffbb51) !important; */
  border-radius: 10px !important;
  border: none;
  color: black !important;
  transition: none !important;
  height: 50px !important;
  outline: none !important;
  border: none !important;
  font-size: 16px !important;
  font-family: "Circular Std Medium";
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.dropdown-toggle-split::after {
  margin-left: 50px !important;
}

.dropdown-toggle::after {
  display: none !important;
}

.search-bar-input {
  background: white !important;
  /* border-bottom: 2px solid #ced1d7d9 !important; */
  margin-top: -0.5rem;
  margin-right: 1.5rem;
  /* margin-left: -0.5rem; */
}

.payment-input {
  background: white !important;
  height: 42px !important;
  /* width: 50% !important; */
}

.prevent-select {
  -webkit-user-select: none;
  /* Safari */
  -ms-user-select: none;
  /* IE 10 and IE 11 */
  user-select: none;
  /* Standard syntax */
}
