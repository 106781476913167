body {
  margin: 0;
  font-family: "Circular Std";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
@font-face {
  font-family: "Circular Std";
  src: local("CircularBold"),
    url("../src/assets/font/CircularStd-Bold.otf") format("truetype");
  /* font-weight: Bold; */
}
@font-face {
  font-family: "Circular Std Book";
  src: local("CircularBook"),
    url("../src/assets/font/CircularStd-Book.otf") format("truetype");
  font-weight: Bold;
  /* font-style: initial; */
}
@font-face {
  font-family: "Circular Std Medium";
  src: local("CircularMedium"),
    url("../src/assets/font/CircularStd-Medium.ttf") format("truetype");
  /* font-weight: normal; */
  /* font-style: initial; */
}

@font-face {
  font-family: "Circular Std Bold";
  src: local("CircularMedium"),
    url("../src/assets/font/CircularStd-Bold.ttf") format("truetype");
  font-weight: normal;
  /* font-style: initial; */
}
@font-face {
  font-family: "Circular Std Black";
  src: local("CircularBlack"),
    url("../src/assets/font/CircularStd-Black.ttf") format("truetype");
  font-weight: normal;
  /* font-style: initial; */
}
@font-face {
  font-family: "Circular Std Book Italic";
  src: local("CircularBlack"),
    url("../src/assets/font/CircularStd-BookItalic.ttf") format("truetype");
  font-weight: normal;
  /* font-style: initial; */
}
