.form-group {
  margin-bottom: 20px;
}

label {
  font-weight: normal;
  font-size: 13px;
}

.form-control {
  height: 36px;
  padding-left: 5px;
  padding-top: 15px;
  box-shadow: none !important;
  border-width: 0 0 1px 0;
  border-radius: 0;
  outline: none !important;
}

.form-control::placeholder {
  color: #6c757d;
  opacity: 1;
}

.form-control:focus {
  border-color: #ccc;
  outline: none !important;
}

.input-group-text {
  background: #f6f6f8 !important;
  border: 1px solid transparent !important;
  width: 55px !important;
  justify-content: center;
}

.input-group-prepend {
  height: 56px !important;
}

input {
  background: #f6f6f8 !important;
  padding-left: 25px !important;
  height: 54px !important;
  border: none !important;
  color: #132649 !important;
  font-size: 17px !important;
  outline: none !important;
  font-family: 'Circular Std Medium' !important;
}

input[type=time] {
  width: 190px;
}