/* .btn {
  background: antiquewhite !important;
} */

.btn,
.btn:active {
  font-size: 14px;
  font-weight: bold;
  background-image: linear-gradient(to right, #f1ae01, #ffbb51) !important;
  border-radius: 10px !important;
  border: none;
  color: white !important;
  transition: none !important;
  height: 50px !important;
  outline: none !important;
  border: none !important;
  font-size: 20px !important;
  font-family: 'Circular Std Bold' !important;
}
/* .btn:hover,
.btn:focus {
  background: #f1ae01 !important;
} */
.colored-btn,
.colored-btn:active {
  font-size: 14px;
  font-weight: bold;
  border-radius: 10px !important;
  border: none;
  transition: none !important;
  outline: none !important;
  /* border: none !important; */
  font-size: 20px !important;
  font-family: 'Circular Std Bold' !important;
}
