.custom-select {
  background: #f6f6f8 !important;
  --bs-form-select-bg-img: url('data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27%3e%3cpath fill=%27none%27 stroke=%27%23343a40%27 stroke-linecap=%27round%27 stroke-linejoin=%27round%27 stroke-width=%272%27 d=%27m2 5 6 6 6-6%27/%3e%3c/svg%3e');
  padding-left: 25px !important;
  appearance: auto !important;
  height: 54px !important;
  border: none !important;
  color: #132649 !important;
  font-size: 17px !important;
  outline: none !important;
  font-family: 'Circular Std Medium' !important;
}
