.HeadingCancellation {
  font-family: Circular Std Bold;
  color: #00071a;
  font-weight: 700;
  font-size: 50px;
  padding-top: 6rem;
}
.paragraphH4 {
  font-family: Circular Std Bold;
  color: #132649;
  font-size: 20px;
  margin-top: 3rem;
}
.H4detail {
  font-family: Circular Std Book;
  color: #8892a3;
  margin-top: 0.5rem;
}
.listingg {
  font-size: 13px;
}
.HeadingHelp {
  font-family: Circular Std Bold;
  color: #00071a;
  font-weight: 700;
  font-size: 50px;
  padding-top: 5rem;
}
.popularQueriesHeading {
  font-family: Circular Std Medium;
}
.ListItem {
  font-family: Circular Std Book;
  color: #132649;
}
/* ul {
  list-style-image: url("https://upload.wikimedia.org/wikipedia/commons/e/e1/Yellow_Dots_Golden.svg");
}
 */
/* ul {
  list-style: disc;
}
ul li::marker {
  color: #ffbb51;
  font-size: 1rem;
} */
